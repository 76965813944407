import { ref, isRef } from 'vue'

export function pagination(load, { params, pageMode = false } = {}) {
  if (!isRef(params)) params = ref({})

  const list = ref([])
  const page = ref(0)
  const page_size = ref(10)
  const loading = ref(false)
  const finished = ref(false)
  const refreshing = ref(false)
  const error = ref(false)
  const member_id = ref('')

  let _data = null

  const onReset = () => {
    list.value = []
    page.value = 0
    page_size.value = 10
  }

  const onLoad = async (currentPage) => {
    const data = Object.assign({}, params.value)

    if (pageMode) {
      Object.assign(data, { page: currentPage, page_size: page_size.value })
    } else {
      const offset = (currentPage - 1) * page_size.value
      Object.assign(data, { offset, limit: page_size.value })
    }

    _data = data

    try {
      loading.value = true
      const chunk = await load(data)
      loading.value = false
      // 获取成员id
      if (chunk.data.member_id) {
        member_id.value = chunk.data.member_id
      }

      if (_data !== data) return false

      if (refreshing.value) {
        list.value = []
        finished.value = false
      }

      list.value.push(...chunk.data.list)
      page.value = currentPage

      if (error.value) {
        error.value = false
      }
      if (chunk.data.list.length < page_size.value) {
        finished.value = true
      }
    } catch (e) {
      error.value = true
    }

    // return Promise.resolve(true)
    return true
  }

  const onRefresh = async () => {
    refreshing.value = true
    const complete = await onLoad(1)
    if (complete) refreshing.value = false
    return complete
  }

  const onNext = async () => {
    loading.value = true
    const complete = await onLoad(page.value + 1)
    if (complete) loading.value = false
    return complete
  }

  return {
    list,
    page,
    page_size,
    params,
    loading,
    finished,
    member_id,
    refreshing,
    error,
    onReset,
    onLoad,
    onRefresh,
    onNext
  }
}
