<template>
  <div
    class="card-item flex flex-align-center flex-justify-between ptb10 plr15 bgf mb10"
  >
    <div class="card-info flex-g1 flex-s1">
      <div class="info-title fs14 cl17 mb10 txt-line-1 fw500">
        {{ activityItem.name }}
      </div>
      <div class="info-row mb5 fs12">
        <span class="info-label cl17">类型：</span>
        <span class="info-value cl8c">
          {{ showType(activityItem.type) }}
        </span>
      </div>
      <div class="info-row fs12">
        <span class="info-label cl17">时间：</span>
        <span class="info-value cl8c">
          {{ activityItem.start_time }} 至 {{ activityItem.end_time }}
        </span>
      </div>
    </div>
    <div class="share-btn flex-g0 flex-s0">
      <van-button
        size="small"
        plain
        color="#888"
        @click="onShare(activityItem, member_id)"
      >
        分享
      </van-button>
    </div>
  </div>
</template>

<script>
import { Button, Toast } from 'vant'
import { sendChatMessageAllType } from '@/utils/nwechat.js'
import { formatParamsDistribution } from '@/utils/index.js'
export default {
  components: {
    [Button.name]: Button
  },
  props: {
    activityItem: {
      type: Object,
      default: () => {}
    },
    member_id: {
      type: String,
      default: ''
    },
    customer_id: {
      type: String,
      default: ''
    },
    chat_id: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    async function onShare(item, member_id) {
      Toast.loading({
        message: '加载中',
        forbidClick: true,
        duration: 0
      })
      try {
        let title = item.name
        let imgUrl = item.banner_img
        let page = ''
        let appid = ''
        if (
          item.type == 'associated_goods' ||
          item.type == 'not_associated_goods'
        ) {
          appid = 'wxbecd55f783f6a0cd' //小程序的appid 群店:wxbecd55f783f6a0cd; aosifen: wx754859a6c762e762
          page = `pagesIntegral/index/activity.html?id=${item.id}`
          // page = `pagesIntegral/index/activity.html?id=${item.id}&member_id=${member_id}`
        } else {
          page = item.page_url
          appid = item.app_id
        }

        page = await formatParamsDistribution(
          page,
          props.customer_id,
          props.chat_id,
          'score_activity'
        )

        let shareObj = {
          appid: appid, //小程序的appid
          title,
          imgUrl, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
          page //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
        }
        await sendChatMessageAllType('miniprogram', {
          miniprogram: { ...shareObj }
        })
        Toast.clear()
      } catch (e) {
        console.log('报错', e)
        Toast.clear()
      }
    }

    const showType = (type) => {
      let obj = {
        not_associated_goods: '不关联商品',
        associated_goods: '关联商品',
        associated_mini_program: '关联小程序'
      }
      return obj[type]
    }
    return {
      onShare,
      showType
    }
  }
}
</script>
